import { useLocation } from '@remix-run/react'
import { useEffect, useState } from 'react'
import { ExploDateRange } from '~/routes/app.$customerName.$locationName.dashboards.$title.$exploId/route'

export const useBaseURL = () => {
  const [baseURL, setBaseURL] = useState('')

  useEffect(() => {
    if (window !== undefined) {
      if (window?.location) {
        setBaseURL(window.location.origin)
      }
    }
  }, [])

  return baseURL
}

/**
 * It appears explo is updating the url params by utilizing window's replaceState, which does not trigger a rerender
 * Remix does not see this url param update, so we need to add additional listeners to capture search params
 * added this way
 */

export const useExploSearchDateRangeParams = (): ExploDateRange | undefined => {
  const location = useLocation()
  const [searchParams, setSearchParams] = useState(
    new URLSearchParams(location.search)
  )

  useEffect(() => {
    const updateSearchParams = () => {
      setSearchParams(new URLSearchParams(window.location.search))
    }

    const handlePopState = () => {
      updateSearchParams()
    }

    // Listen for popstate events
    window.addEventListener('popstate', handlePopState)

    // Monkey-patch pushState and replaceState to listen for those changes as well
    const originalPushState = window.history.pushState
    const originalReplaceState = window.history.replaceState

    window.history.pushState = function (state, title, url) {
      originalPushState.apply(this, [state, title, url])
      updateSearchParams()
    }

    window.history.replaceState = function (state, title, url) {
      originalReplaceState.apply(this, [state, title, url])
      updateSearchParams()
    }

    // Clean up event listeners and patches on unmount
    return () => {
      window.removeEventListener('popstate', handlePopState)
      window.history.pushState = originalPushState
      window.history.replaceState = originalReplaceState
    }
  }, [])

  return searchParams.get('date_picker_main')
    ? JSON.parse(searchParams.get('date_picker_main') as string)
    : undefined
}
